@require '_variables'
@require '_base'
@require '_components/*'
@require '_containers/*'

.t_left
  text-align: left
.t_center
  text-align: center
.t_right
  text-align: right

.t_alt
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 0;
  white-space: nowrap;
  overflow: hidden;

.content
  width: 1280px
  margin: 88px auto
  padding: 0 88px
  +sp()
    margin: 40px auto
    width: 100%
    padding: 0 1.5rem
      
.sample
  background: #f0f0f0
  overflow: hidden
  > .content
    margin-top: 44px
    margin-bottom: 44px
  &-title
    display: inline-block
    background: white
    padding: 16px
    box-shadow: $enji 8px 8px 0
  &-list
    display: flex
    flex-direction: column
    flex-wrap: wrap
    height: 160px
    margin: 40px -16px 40px 0
    > li
      font-size: 32px
      font-weight: bold
      margin-top: 16px
      width: calc((100% - 64px)/3)
      margin-right: 16px
      +pc()
        height: 36px
        > small
          font-size: 12px
          vertical-align: top
    +sp()
      margin: 1rem -16px 1rem 0
      > li
        font-size: 1rem
        margin-top: .5rem
        width: calc((100% - 16px)/2)
        margin-right: 0

  &-wrapper
    display: block
    margin-left: -16px
    margin-right: -16px
    margin-bottom: -40px
    overflow: hidden
    &:after
      content: ''
      clear: both
      display: table
      width: 100%
      height: 0
  &-item_1-3
    float: left
    padding: 0 16px 40px
    +pc()
      width: 33.33%
    +sp()
      width: 50%
    > figure
      margin: 0
      width: 100%
      background: no-repeat center center / cover
      padding-top: (300/4)%
      +sp()
        padding-top: 100%
  &-item_1-4
    float: left
    padding: 0 16px 40px
    +pc()
      width: 25%
    +sp()
      width: 50%
    > figure
      margin: 0
      width: 100%
      height: 0
      padding-top: (400/3)%
      background: no-repeat center center / cover
      +sp()
        padding-top: 100%
      &.square
        padding-top: 100%

.link_more
  display: inline-flex
  color: $enji
  align-items: center
  &:after
    content: ""
    display: block
    margin-left: 8px
    border-top: 4px solid transparent
    border-right: 0
    border-bottom: 4px solid transparent
    border-left: 4px solid $enji
